import i18n from "@/i18n.js";
import router from "@/router";
import OrderService from "@/modules/assign_camp/services/camp_orders.service";

export const camp_orders = {
    namespaced: true,
    state: () => ({
        arr_status_standing_committee: [3, 6],
        arr_status_sharer: [2, 7],
        order: null,
        camp: null,
        questions: [],
        rejected_attach: 0,
        rejected_answer: 0,
        is_visible_edit_send_btn: true
    }),
    mutations: {
        VISIBLE_BTN_SEND(state, data) {
            state.is_visible_edit_send_btn = data
        },
        SET_REJECT_ATTACH(state, data) {
            state.rejected_attach = data
        },
        SET_REJECT_ANSWER(state, data) {
            state.rejected_answer = data
        },
        INCREASE_ANSWER(state) {
            state.rejected_answer++
        },
        DECREASE_ANSWER(state) {
            state.rejected_answer--
        },
        INCREASE_ATTACH(state) {
            state.rejected_attach++
        },
        SET_ORDER(state, data) {
            state.order = data;
        },
        SET_CAMP(state, data) {
            state.camp = data;
        },
        SET_QUESTIONS(state, data) {
            state.questions = data;
        }
    },
    getters: {
        selectedCamp(state) {
            return state.camp;
        },
    },
    actions: {
        handel_store_attachment({ commit, rootState }, data) {
            // return 1;
            const id = data.get('camp_order_id');
            return OrderService.store_grievance(data).then(
                (response) => {
                    // console.log(response)
                    rootState.table.items.map(v => {
                        if (v.id == id) {
                            v.status_id = response.data.data.status_id
                            v.grievance = response.data.data.grievance
                            v.status.name = response.data.data.status ? response.data.data.status.name : ""
                            return v;
                        }
                    });
                    rootState.form.loader = false
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                    commit('form/SET_DIALOG', false, { root: true })
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );
        },
        handel_reject_order({ commit, rootState }, data) {
            console.log(...data);

            // alert('done')
            // return false;
            return OrderService.forworder_order(data).then(
                (response) => {
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.style_form = [];
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };

                    setTimeout(function () {
                        router.push({ name: "OrderCampPage" });
                    }, 1000);
                },
                (error) => {

                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );
        },
        cancel_order({ rootState }, item) {
            var data = { camp_order_id: item.id, status_id: 9 }
            console.log(data);
            return OrderService.forworder_order(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page;
            dispatch("getData", {});
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" });
        },

        // filter section
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {});
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true });
        },

        getData({ rootState }, data) {
            console.log(data);
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty("reset")) {
                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty("type") && data.type == "pre_page") {
                formData.append("paginate", data.pre_page);
            } else {
                if (rootState.table.paginate.itemsPerPage != "")
                    formData.append("paginate", rootState.table.paginate.itemsPerPage);
            }
            return OrderService.get_orders(
                rootState.table.paginate.page,
                formData
            ).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data;
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    };
                    if (data.hasOwnProperty("reset") && data.reset) {
                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
    },
};