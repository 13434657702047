import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "@/views/Layout/BlankLayout.vue";
import Middleware from "@/middleware";
const Page = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/Show.vue");
const New = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/New.vue");
const Process = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/Process.vue");
// const Edit = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/Edit.vue");
export const TemporaryAllocationOrder = {
    path: "/",
    component: DashboardLayout,
    name: "TemporaryAllocationOrder",
    children: [
        {
            path: "/temp-allocation-orders/create",
            name: "NewTemporaryAllocationOrder",
            component: New,
            meta: {
                groupName: "Assign",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "allocation-create",
            },
        },
        {
            path: "/temp-allocation-orders/details/:id",
            name: "EditTemporaryAllocationOrder",
            component: Process,
            meta: {
                groupName: "TemporaryAllocationOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "allocation-proceess",
            },
        },

        {
            path: "/temp-allocation-orders",
            name: "TemporaryAllocationOrderPage",
            component: Page,
            meta: {
                groupName: "TemporaryAllocationOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-allocation-index",
            },
        },
    ],
};

