import router from '@/router'
import Vue from "vue";
import i18n from '@/i18n.js'
import OrderService from '../services/grievance.service';
export default {
    namespaced: true,
    state: () => ({
    }),
    actions: {
        // grievance

        approve_order({ rootState }, data) {
            return OrderService.forworder_order(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == data.camp_order_grievance_id) {
                            v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        reject_order({ rootState }, data) {

            return OrderService.reject_order(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == data.id
                        ) {
                            v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            v.note = response.data.data.note
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        cancel_order({ rootState }, item) {
            var data = { camp_order_id: item.id, status_id: 9 }
            console.log(data);
            return OrderService.forworder_order(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            // v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },



        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                if (rootState.form.filters.length > 0) {
                    rootState.form.filters.filter(function (filter) {
                        formData.append(filter.name, filter.value);
                    });

                }
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return OrderService.get_orders(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {
                        if (response.data.filters) {

                            rootState.form.filters = response.data.filters;
                        }
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};