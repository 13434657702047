<template>
  <v-navigation-drawer
    width="100%"
    fixed
    app
    floating
    :value="drawer"
    :right="$vuetify.rtl"
    class="ms-4"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
    :style="
      bgDrawer
        ? `background-image:  url('${require('../assets/img/tsleem/new-bg-login.jpg')};`
        : ''
    "
  >
    <!-- <div class="child-drawer"> -->
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <router-link to="/dashboard">
            <div
              class="v-navigation-drawer-brand pa-5 d-flex justify-center align-center"
            >
              <img
                src="@/assets/img/logo2.png"
                class="navbar-brand-img ms-3 mb-3 mt-3"
                width="27%"
              />
              <span
                class="text-brand text-dark ms-2 font-weight-bold font-poppins text-sm"
                >Tsleem</span
              >
            </div>
          </router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <ListItemSide
      @listClose="listClose"
      :items="system"
      :header="header_system"
    ></ListItemSide>
    <!-- </div> -->
  </v-navigation-drawer>
</template>
<script>
import ListItemSide from "../views/Current/Components/ListItem.vue";
import {
  tamplates,
  assign_camp,
} from "@/modules/assign_camp/drawer/assign_camp";

import { dashboard } from "@/modules/dashboard/drawer/dashboard";

import { user } from "@/modules/users/drawer/users";
import license from "@/modules/license/drawer/license";

import { order } from "@/modules/orders/drawer/order";

export default {
  name: "drawer",
  components: {
    ListItemSide,
  },
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
    bgDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    header_system: {
      title: "system",
      permission: "user,role,category",
    },
    system: [dashboard, user, tamplates, assign_camp, license, order],
  }),
  methods: {
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
<style>
.v-navigation-drawer {
  max-width: 17.25rem !important;
}
.v-navigation-drawer.v-navigation-drawer--is-mobile {
  z-index: 55555 !important;
}
.v-navigation-drawer .v-list-item__title {
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: pre-wrap;
}
.v-navigation-drawer__content .v-list-group__header__append-icon .v-icon {
  color: #333 !important;
}
.child-drawer {
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: #c0946f40;
}

.v-navigation-drawer__content
  .v-list-item
  .v-list-item-mini
  + .v-list-item__content {
  color: #3a416fd1 !important;
}

.v-navigation-drawer__content
  .v-list-item.v-list-item--active
  .v-list-item-mini
  + .v-list-item__content {
  color: #3a416f !important;
  font-weight: bold !important;
}
</style>
