
import i18n from '@/i18n.js'
import CategoryService from '../services/category.service';
export const category = {
    namespaced: true,
    state: () => ({

    }),
    actions: {

        add_category({ rootState }, data) {
            return CategoryService.add_category(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    // rootState.form.dialog = false
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                    setTimeout(function () {
                        window.location.reload()
                    }, 1200);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        edit_category({ rootState, state }, data) {
            return CategoryService.edit_category(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                    setTimeout(function () {
                        window.location.reload()
                    }, 1200);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },


    }
};