import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";
import AuthCoverLayout from "@/views/Layout/AuthCoverLayout";
import Middleware from "@/middleware/";

const FileForm = () => import("@/modules/assign_camp/views/Appointments/FileForm.vue");
const BlankRegisterDone = () => import("@/modules/auth/views/Done.vue");

Vue.use(VueRouter);

/************** Start Routes ***********************/

let FilePage = {
    path: "/",
    component: BlankLayout,
    name: "FileSignaturePage",
    children: [
        {
            path: "appointments/:id/form/:form/view",
            name: "AppointmentFormView",
            component: FileForm,
            meta: {
                groupName: "AnswerForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-view",
            },
        },


        {
            path: "/done",
            name: "BlankRegisterDone",
            component: BlankRegisterDone,
            meta: {
                groupName: "Done",
                middlewares: [Middleware.authReject],
            },
        },


    ],
};

let PageError = {
    path: "/error",
    component: AuthCoverLayout,
    name: "Errors",
    children: [{
        path: "/403",
        meta: {

            title: "403",
        },
        name: "403",
        component: () =>
            import("@/views/Error/403.vue"),
    },
        // {
        //     path: "/404",
        //     name: "404",
        //     component: () =>
        //         import("@/views/Error/403.vue"),
        // },
    ],
};
import {
    DashboardPage,
} from "@/modules/dashboard/routes/dashboard"


import {
    Login,
    // SignUp,
    // RegisterFormPages,
} from "@/modules/auth/routes/auth"

import {

    QuestionsPage,
    QuestionCategoriesPage,
    AnswerFromPage,
    TamplateFormPage
} from "@/modules/form/routes/form"

// import {
//     LocationsPage,
// } from "@/modules/location_form/routes/form"
import {
    orderCampPage,
} from "@/modules/assign_camp/routes/camp_order"

import {
    SquaresPage,
    CampsPage,
    CountryPage,
    AssignsPage,
    AppointmentsPage,

} from "@/modules/assign_camp/routes/camps";

import StationPage from "@/modules/assign_camp/routes/station";
import { TemporaryAllocationOrder } from "@/modules/assign_camp/routes/tempAllocation";
import { TemporaryPermitOrder } from "@/modules/license/routes/temp-permit";

import { Roles, TypesPage, EmployeePage, Admins, CategoriesPage } from "@/modules/users/routes/users";

/*
*****************************
start order section
*****************************
*/

import orders from "@/modules/orders/routes/order";
import service from "@/modules/orders/routes/service";
import pledge from "@/modules/orders/routes/pledge";
import EngGuides from "@/modules/orders/routes/EngGuides";
import TmpLicensePage from "@/modules/orders/routes/license";
import ReadinessLicenseOrderPage from "@/modules/orders/routes/readinessLicense";

// import ticket from "@/modules/tickets/routes/ticket";
// import ticket_subject from "@/modules/tickets/routes/subject";
/************** End Routes ***********************/
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: "/dashboard",
        component: DashboardLayout,
        children: [
            {
                path: "/testing-api",
                name: "test",
                component: () => import("@/views/Current/Components/Test.vue"),
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.is_admin,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    // permissions: "dashboard-optimization-sites",
                },
            },
        ],

    },

    DashboardPage,
    Login,
    // RegisterFormPages,
    // SignUp,
    CampsPage,
    CountryPage,
    SquaresPage,
    AssignsPage,
    AppointmentsPage,
    AnswerFromPage,
    TamplateFormPage,
    CategoriesPage,
    EmployeePage,
    QuestionsPage,
    QuestionCategoriesPage,
    TypesPage,
    Roles,
    Admins,
    FilePage,
    PageError,
    StationPage,
    TemporaryAllocationOrder,
    // LocationsPage,
    orderCampPage,
    TemporaryPermitOrder,
    // orders,
    // service,
    // pledge,
    // EngGuides,
    // TmpLicensePage,
    ReadinessLicenseOrderPage,
    // ticket,
    // ticket_subject,
    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [
            {
                path: "/404",
                meta: {
                    title: "404",
                },
                name: "404",
                component: () => import("@/views/Error/404.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});


import i18n from '@/i18n.js'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta.title)
            document.title = i18n.t(to.meta.title);
    });
});

export default router;
