class License {
    get(page, data) {
        if (page)
            return axios.post('temporary-permits/index?page=' + page, data)
        else
            return axios.post('temporary-permits/index', data)
    }

    get_locations() {
        return axios.get('camp-order/get_camps')
    }

    get_details(id) {
        return axios.get('temporary-permits/details/'+ id)
    }
    store_order(data) {
        return axios.post('temporary-permits/store', data)
    }
    approve_order(data) {
        return axios.post('temporary-permits/accepted', data)
    }
    reject_order(data) {
        return axios.post('temporary-permits/reject', data)
    }
    sign_order(data) {
        return axios.post('temporary-permits/sign', data)
    }

    // api about form question answer
    get_forms() {
        return axios.get('temporary-permits/forms')
    }
    get_form(id) {
        return axios.get('temporary-permits/question-answer/' + id)
    }
    store_form(data) {
        return axios.post('temporary-permits/answer-questions', data)
    }
    create_form_order(data) {
        return axios.post('temporary-permits/reject', data)
    }
    update_form(data) {
        // return axios.post('temporary-permits/answer-questions', data)
        return axios.post('temporary-permits/update-all-answers', data)

    }
}
export default new License();
