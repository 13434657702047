
export const user = {
    action: "fa fa-users",
    title: "Users",
    permission: "user",
    items: [
        {
            title: "Admins",
            prefix: "I",
            link: "/users",
            permission: "user-index",
        },
        {
            title: "new main user",
            prefix: "C",
            link: "/create-user",
            permission: "user-create",
        },

        {
            title: "userRequest",
            prefix: "R",
            link: "/users/request",
            permission: "user-request",
        },

        {
            title: "EmployeePage",
            prefix: "K",
            link: "/employees",
            permission: "user-employee-index",
        },
        {
            title: "Roles",
            prefix: "K",
            link: "/roles",
            permission: "user-role-index",
        },
        {
            title: "Categories",
            prefix: "K",
            link: "/categories",
            permission: "user-category-index",
        },

        {
            prefix: "C",
            title: "UserContactPage",
            link: "/user-contacts",
            permission: "user-contact-index",
        },
    ],
}


