import Vue from 'vue'
class CategoryService {
  // categories
  get_categories() {
    return axios.get('general/gov-category', {
      baseURL: Vue.prototype.$baseMenaURL + 'api'
    })
  }
  get_category(id) {
    return axios.get('general/gov-category/edit/' + id, {
      baseURL: Vue.prototype.$baseMenaURL + 'api'
    })
  }
  add_category(data) {
    return axios.post('general/gov-category/store', data, {
      baseURL: Vue.prototype.$baseMenaURL + 'api'
    })
  }
  edit_category(id, data) {
    return axios.post('general/gov-category/update/' + id, data, {
      baseURL: Vue.prototype.$baseMenaURL + 'api'
    })
  }
  destroy_category(id) {
    return axios.delete('general/gov-category/destroy/' + id, {
      baseURL: Vue.prototype.$baseMenaURL + 'api'
    })
  }
}
export default new CategoryService();