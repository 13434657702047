import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Orders",
    children: [
        {
            path: "/blacklist",
            name: "blacklistPage",
            component: () => import("@/modules/orders/views/Orders/DesignOfficeBlackList.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-blacklist-index",
            },
        },
        {
            path: "/orders/create",
            name: "NewOrder",
            component: () => import("@/modules/orders/views/Orders/New.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-create",
            },
        },
        {
            path: "/orders/update/:id",
            name: "EditOrder",
            component: () => import("@/modules/orders/views/Orders/Edit.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-update",
            },
        },
        {
            path: "/orders/re-customize/:id",
            name: "CustomizeOrder",
            component: () => import("@/modules/orders/views/Orders/Reassign.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign",
            },
        },
        {
            path: "/orders",
            name: "OrderPage",
            component: () => import("@/modules/orders/views/Orders/Show.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-index",
            },
        },
        {
            path: "/specialties",
            name: "Specialties",
            component: () => import("@/modules/orders/views/Orders/Specialists.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-specialists-index",
            },
        },
        {
            path: "/orders/consulting",
            name: "OrderCinsultingPage",
            component: () => import("@/modules/orders/views/Orders/Consulting.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-consulting-index",
            },
        },
        {
            path: "/cancel-requests",
            name: "OrderCancelRequestPage",
            component: () => import("@/modules/orders/views/Orders/CancelRequest.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-cancel-request-index",
            },
        },
        {
            path: "/orders/details/:id",
            name: "DetailPage",
            component: () => import("@/modules/orders/views/Orders/Details.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-details",
            },
        },

        {
            path: "/orders/processing/:id",
            name: "ProcessingPage",
            component: () => import("@/modules/orders/views/Orders/Processing.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-processing-tsleem",
            },
        },

        {
            path: "/orders/prepare/:id",
            name: "OrderPreparePage",
            component: () => import("@/modules/orders/views/Orders/Prepare.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-prepare",
            },
        },
        // {
        //   path: "/orders/pledge/:id",
        //   name: "OrderPledgeDetailPage",
        //   component: () => import("@/modules/orders/views/Orders/PledgeDetail.vue"),
        //   meta: {
        //     groupName: "Order",
        //     middlewares: [
        //       Middleware.auth,
        //       Middleware.permission,
        //       Middleware.signeture,
        //     ],
        //     permissions: "order-detail-pledge",
        //   },
        // },
        {
            path: "/orders/tracking/:id",
            name: "OrderTrackingPage",
            component: () => import("@/modules/orders/views/Orders/Tracking.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-tracking",
            },
        },
        {
            path: "/reports",
            name: "OrderReportPage",
            component: () => import("@/modules/orders/views/Orders/Report.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-report-index",
            },
        },
    ],

}