class CampOrderService {

    /*********************
     * Order Api
     */
    get_orders(page, data) {
        return axios.post('camp-order?page=' + page, data)
    }
    get_order(id) {
        return axios.get('camp-order/show/' + id)
    }
    store_order(data) {
        return axios.post('camp-order/store', data)
    }
    forworder_order(data) {
        return axios.post('camp-order/Forward-order', data)
    }
    tracking_order(id) {
        return axios.get('camp-order/tracking/' + id)
    }
    get_camps() {
        return axios.get('camp-order/get_camps')
    }

    store_grievance(data) {
        return axios.post('camp-order-grievance/store', data)
    }
    bulk_sign_order(data) {
        return axios.post('camp-order/bulk-forward-order', data)
    }
    /*********************
     * Questions Api
     */
    get_questions_with_answer(id) {
        return axios.get('camp-order/get-question-answer/' + id)
    }
    get_questions() {
        return axios.get('camp-order/get_questions')
    }
    store_questions(data) {
        return axios.post('camp-order/q-answer', data)
    }
    update_question(data) {
        return axios.post('camp-order/update-answer', data)
    }
    update_questions(data) {
        return axios.post('camp-order/edit-answer', data)
    }
    process_question(data, type) {
        if (type == 'accepted')
            return axios.post('camp-order/approve-answer', data)
        else if (type == 'rejected')
            return axios.post('camp-order/reject-answer', data)
    }

    /*********************
     * Attachments Api
     */
    get_attachments(id, data) {
        return axios.post('camp-order-attachments/get-files/' + id, data)
    }
    update_attachment(data) {
        return axios.post('camp-order-attachments/update', data)
    }
    remove_attachment(id) {
        return axios.delete('camp-order-attachments/remove-file/' + id)
    }
    store_attachments(data) {
        return axios.post('camp-order-attachments/store', data)
    }
    update_attachments(data) {
        return axios.post('camp-order-attachments/edit-order-files', data)
    }
    process_attachments(data, type) {
        if (type == 'accepted')
            return axios.post('camp-order-attachments/accept-file', data)
        else if (type == 'rejected')
            return axios.post('camp-order-attachments/reject-file', data)
    }
}

export default new CampOrderService();