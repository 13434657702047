class TempAllocationService {
    index() {
        return axios.post('temp_allocation/index')
    }
    get_data(page, data) {
        return axios.post(`temp_allocation/index?page=` + page, data);
    }
    add(data) {
        return axios.post('temp_allocation/store', data)
    }
    edit(id, data) {
        return axios.post('temp_allocation/update/' + id, data)
    }
    destroy(id) {
        return axios.delete('temp_allocation/delete/' + id)
    }
}
export default new TempAllocationService();