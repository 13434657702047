import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

// question
const OrderPage = () => import("@/modules/assign_camp/views/OrderCamp/Show.vue");
const NewOrder = () => import("@/modules/assign_camp/views/OrderCamp/New/New.vue");
const EditOrder = () => import("@/modules/assign_camp/views/OrderCamp/Edit/Edit.vue");
const ProcessOrder = () => import("@/modules/assign_camp/views/OrderCamp/Processing/Index.vue")
const CorrectOrder = () => import("@/modules/assign_camp/views/OrderCamp/Correct/Correct.vue")
const DetailsOrder = () => import("@/modules/assign_camp/views/OrderCamp/Details/Details.vue")
const TrackingOrder = () => import("@/modules/assign_camp/views/OrderCamp/Tracking.vue")
const GrievanceRquest = () => import("@/modules/assign_camp/views/OrderCamp/GrievanceRquest.vue")
export const orderCampPage = {
    path: "/",
    component: DashboardLayout,
    name: "OrderCamp",
    children: [{
        path: "/camp-orders/create",
        name: "NewOrderCamp",
        component: NewOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-create",
        },
    },
    {
        path: "/camp-orders/edit/:id",
        name: "EditOrderCamp",
        component: EditOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-edit",
        },
    },
    {
        path: "/camp-orders/fix/:id",
        name: "CorrectOrderCamp",
        component: CorrectOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-fix",
        },
    },
    {
        path: "/camp-orders/details/:id",
        name: "DetailsOrderCamp",
        component: DetailsOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-details",
        },
    },
    {
        path: "/camp-orders/tracking/:id",
        name: "TrackingOrderCamp",
        component: TrackingOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-tracking",
        },
    },
    {
        path: "/camp-orders",
        name: "OrderCampPage",
        component: OrderPage,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-camporder-index",
        },
    },
    {
        path: "/camp-orders/process/:id",
        name: "ProcessOrderCamp",
        component: ProcessOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-sharer-process",
        },
    },
    {
        path: "/camp-orders/process-committee/:id",
        name: "ProcessOrderCamp",
        component: ProcessOrder,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camporder-process",
        },
    },
    {
        path: "/grievance-order",
        name: "GrievanceRquestCamp",
        component: GrievanceRquest,
        meta: {
            groupName: "OrderCamp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-grievance-index",
        },
    },
    ],
};



