class ReadinessLicenseService {
    index() {
        return axios.post('readiness-license/index')
    }
    get_licensess(page, data) {
        return axios.post('readiness-license/index?page' + page, data)
    }

    edit_licenses(id) {
        return axios.get('readiness-license/edit/' + id)
    }
    get_assign_camps() {
        return axios.get('readiness-license/create')
    }
    add_licenses(data) {
        return axios.post('readiness-license/store', data)
    }
    proccess_licenses(data) {
        return axios.post('readiness-license/update', data)
    }

}
export default new ReadinessLicenseService();