export const reports = {
    action: "mdi-chart-line",
    title: "reports log",
    permission: "reports",
    items: [
        {
            title: "System Logs",
            prefix: "IS",
            link: "/log-system",
            permission: "reports-system-log",
        },
        {
            title: "Logs",
            prefix: "IS",
            link: "/logs",
            permission: "reports-log",
        },
        {
            title: "Message SMS",
            prefix: "MS",
            link: "/sms",
            permission: "reports-sms",
        },
    ],
}

export const order = {
    action: "fa fa-box",
    title: "Orders",
    permission: "order",
    items: [
        // {
        //     prefix: "O",
        //     title: "Specialties",
        //     link: "/specialties",
        //     permission: "order-specialists-index",
        // },
        // {
        //     title: "ServicePage",
        //     prefix: "SC",
        //     link: "/services",
        //     permission: "order-service-index",
        // },
        // {
        //     title: "InputService",
        //     prefix: "SC",
        //     link: "/services/inputs",
        //     permission: "order-service-input-index",
        // },
        // {
        //     prefix: "P",
        //     title: "Pledges",
        //     link: "/pledges",
        //     permission: "order-pledge-index",
        // },
        // {
        //     prefix: "P",
        //     title: "EngGuides",
        //     link: "/guides",
        //     permission: "order-eng-guides-index",
        // },
        // {
        //     prefix: "O",
        //     title: "Orders",
        //     link: "/orders",
        //     permission: "order-index",
        // },
        // {
        //     prefix: "O",
        //     title: "OrderCinsultingPage",
        //     link: "/orders/consulting",
        //     permission: "order-consulting-index",
        // },
        // {
        //     prefix: "O",
        //     title: "TmpLicense",
        //     link: "/licenses",
        //     permission: "order-tmp-license-index",
        // },
        // {
        //     prefix: "O",
        //     title: "blacklistPage",
        //     link: "/blacklist",
        //     permission: "order-blacklist-index",
        // },
        // {
        //     prefix: "O",
        //     title: "Reports Consultor",
        //     link: "/reports",
        //     permission: "order-report-index",
        // },
        // {
        //     prefix: "O",
        //     title: "OrderCancelRequestPage",
        //     link: "/cancel-requests",
        //     permission: "order-cancel-request-index",
        // },
        {
            prefix: "O",
            title: "ReadinessLicenseOrder",
            link: "/readiness-license-order",
            permission: "order-readiness-index",
        },
    ],
}

export const license = {
    action: "fa fa-handshake",
    title: "Licenses",
    permission: "carLicense-index",
    items: [
        {
            title: "View licenses",
            prefix: "R",
            link: "/request-licenses",
            permission: "carLicense-index",
        },
    ],
}

