export const dashboard = {
    action: "fa fa-home",
    title: "Home",
    permission: "dashboard",
    items: [
        {
            title: "Home",
            prefix: "I",
            link: "/dashboard",
            permission: "",
        },
        // {
        //     title: "Data panels",
        //     prefix: "I",
        //     link: "/dashboard-power-bi",
        //     permission: "dashboard-power-bi-1",
        // },

    ],
};