class GrievanceOrderService {
    get_orders(page, data) {
        return axios.post('camp-order-grievance?page=' + page, data)
    }
    get_order(id) {
        return axios.get('camp-order-grievance/show/' + id)
    }
    store_order(data) {
        return axios.post('camp-order-grievance/store', data)
    }
    forworder_order(data) {
        return axios.post('camp-order-grievance/Forward-order', data)
    }
    reject_order(data) {
        return axios.post('camp-order-grievance/reject-order', data)
    }
}

export default new GrievanceOrderService();