import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "@/views/Layout/BlankLayout.vue";
import Middleware from "@/middleware";
const Page = () => import("@/modules/license/views/permit/Show.vue");
const New = () => import("@/modules/license/views/permit/New.vue");
const Process = () => import("@/modules/license/views/permit/Process.vue");
const Edit = () => import("@/modules/license/views/permit/Edit.vue");
export const TemporaryPermitOrder = {
    path: "/",
    component: DashboardLayout,
    name: "TemporaryPermitOrder",
    children: [
        {
            path: "/temp-permit-orders/create",
            name: "NewTemporaryPermitOrder",
            component: New,
            meta: {
                groupName: "Assign",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "permit-prepare",
            },
        },
        {
            path: "/temp-permit-orders/details/:id",
            name: "DetailsTemporaryPermitOrder",
            component: Process,
            meta: {
                groupName: "TemporaryPermitOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "permit-details",
            },
        },
        {
            path: "/temp-permit-orders/edit/:id",
            name: "EditTemporaryPermitOrder",
            component: Edit,
            meta: {
                groupName: "TemporaryPermitOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "permit-edit",
            },
        },

        {
            path: "/temp-permit-orders/process/:id",
            name: "ProcessTemporaryPermitOrder",
            component: Process,
            meta: {
                groupName: "TemporaryPermitOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "permit-process",
            },
        },

        {
            path: "/temp-permit-orders",
            name: "TemporaryPermitOrderPage",
            component: Page,
            meta: {
                groupName: "TemporaryPermitOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "permit-index",
            },
        },
    ],
};

