import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "ReadinessLicenseOrder",
    children: [
        {
            path: "/readiness-license-order/create",
            name: "NewReadinessLicenseOrder",
            component: () => import("@/modules/orders/views/ReadinessLicenseOrder/New.vue"),
            meta: {
                groupName: "ReadinessLicenseOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "readiness-create",
            },
        },
        {
            path: "/readiness-license-order/details/:id",
            name: "EditReadinessLicenseOrder",
            component: () => import("@/modules/orders/views/ReadinessLicenseOrder/Details.vue"),
            meta: {
                groupName: "ReadinessLicenseOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "readiness-details",
            },
        },
        {
            path: "/readiness-license-order",
            name: "ReadinessLicenseOrderPage",
            component: () => import("@/modules/orders/views/ReadinessLicenseOrder/Show.vue"),
            meta: {
                groupName: "ReadinessLicenseOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-readiness-index",
            },
        },

    ],

}