export default {
    action: "fa fa-handshake",
    title: "Licenses",
    permission: "permit",
    items: [
        {
            title: "View temp permit",
            prefix: "R",
            link: "/temp-permit-orders",
            permission: "permit-index",
        },
    ],
}